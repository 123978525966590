/*-----------------------
 * Banner Style One
------------------------*/

.height-850 {
    min-height: 850px;
    width: 100%;
    padding: 240px 0 190px;
    @media #{$sm-layout} {
        min-height: auto;
        width: 100%;
        padding: 250px 0;
    }
}

.height-940 {
    min-height: 940px;
    width: 100%;
    @media #{$sm-layout} {
        min-height: auto;
        width: 100%;
        padding: 250px 0;
    }
}

.banner-style-1 {
    position: relative;
    background-image: url(../../images/bg/home-one-banner.jpg);
    z-index: 2;
    @media #{$lg-layout} {
        height: auto;
        min-height: auto;
        padding: 100px 0;
        padding-top: 150px;
    }
    @media #{$md-layout} {
        height: auto;
        min-height: auto;
        padding: 100px 0;
        padding-top: 150px;
    }
    @media #{$sm-layout} {
        height: auto;
        min-height: auto;
        padding: 100px 0;
        padding-top: 150px;
    }

    .banner-right-content {
        position: relative;
        // margin-top: 138px;
        @media #{$smlg-device} {
            margin-top: 0px;
        }
        .edu-card {
            min-width: 272px;
            @media #{$sm-layout} {
                min-width: inherit;
            }
        }

        &.free-price-quote-form{
            padding: 2rem;
            box-shadow: 0 1rem 1rem 0 rgb(0 0 0 / 8%);
            border-radius: 1rem;
            background: #fff;

            .header{
                margin-bottom: 32px;
                text-align: center;
                h3{
                    font-size: 24px;
                }
            }

            .body{
                .input-row{
                    margin: 0 0 20px 0;
                    .label{
                        display: flex;
                        align-items: center;
                        p{
                            margin: 0;
                            font-weight: 700;
                        }
                    }

                    .content{
                        input{
                            height: 40px;
                            border: 1px solid;
                            border-radius: 8px;
                            border-color: #e4f3ff;
                            color: #232e45;
                            background-color: #e4f3ff;
                        }
                    }

                    .free-quote-btn{

                    }
                }
            }
        }
    }

    .banner-left-content {
        // margin-top: 60px;
        @media #{$smlg-device} {
            margin-top: 0;
        }
    }

    .inner {
        .content {
            .pre-title {
                letter-spacing: 0.1em;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 16px;
                line-height: 26px;
                color: var(--color-primary);
                display: block;
                margin-bottom: 10px;
            }
            .title {
                font-weight: 800;
                font-size: 42px;
                line-height: 1.25;
                margin-bottom: 30px;
                
                @media #{$lg-layout} {
                    font-size: 52px;
                    margin-bottom: 20px;
                }
                
                @media #{$md-layout} {
                    font-size: 45px;
                }

                @media #{$sm-layout} {
                    font-size: 38px;
                }
            }

            .description {
                margin-bottom: 60px;
                padding-left: 20px;
                position: relative;
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;

                @media #{$lg-layout} {
                    margin-bottom: 30px;
                }
                @media #{$md-layout} {
                    margin-bottom: 30px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 30px;
                    font-size: 18px;
                    line-height: 28px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    width: 2px;
                    height: 73%;
                    background: var(--color-primary);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .work-shop {
        min-width: 193px;
        max-width: 193px;
        background: var(--color-white);
        padding: 20px;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin: 25px -15px 0 auto;
        @media #{$lg-layout} {
            min-width: 100%;
            max-width: 100%;
            margin-right: 0;
        }

        @media #{$md-layout} {
            min-width: 100%;
            max-width: 100%;
            margin-right: 0;
        }

        @media #{$sm-layout} {
            margin-right: 0;
            margin-left: 0;
        }
        .inner {
            .thumbnail {
                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                }
            }

            .content {
                .title {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    margin: 10px 0 0;
                }

                .time {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    display: inline-block;
                    margin-bottom: 20px;
                }
            }
        }
    }
    
    .shape-round {
        position: absolute;
        left: -312px;
        top: 130px;
        z-index: -1;
    }

    .scroll-down-btn {
        position: absolute;
        top: 100%;
        left: calc(50% - 30px);
        transform: translateY(-50%);
        z-index: 1;
    }

    .shape-dot-wrapper {
        .shape {
            position: absolute;
            z-index: -1;

            &.shape-1 {
                top: 97px;
                left: -189px;
            }

            &.shape-2 {
                top: 53px;
                left: 580px;
            }

            &.shape-3 {
                top: 95px;
                right: -160px;
            }

            &.shape-4 {
                bottom: 10px;
                right: -173px;
            }

            &.shape-5 {
                bottom: -23px;
                left: 654px;
            }

            &.shape-6 {
                bottom: -37px;
                left: -255px;
            }
        }
    }

    .eduvibe-hero-one-video {
        .thumbnail {
            margin: 30px -149px 0 -23px;
            @media #{$custom-laptop-device} {
                margin: 0 -70px 0 -30px;
            }
            @media #{$smlg-device} {
                margin: 0 -30px 0 -30px;
            }
            @media #{$large-mobile} {
                margin: -30px 0 0 -30px;
            }
        }
    }

}

