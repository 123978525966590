.edu-card{
    &.card-type-1{
        .inner{
            display: flex;
            flex-direction: row;
            .thumbnail{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 40%;
            }
            .content{
                width: 60%;
            }
        }
    }
    
}

.document-title{
    margin-bottom: 20px;
    color: #525fe1;
    .document-title-section{
        cursor: pointer;
        color: #525fe1;
        padding-right: 20px;
        padding-left: 20px;
        border-right: 2px solid lightgrey;
    }

    .document-title-section:hover{
        color: black;
    }
}

.document-card-box{
    cursor: pointer;
    border: 1px solid lightgrey;
    box-shadow: 0 1px 1px #e3e3e3;
    border-radius: 5px;
    padding: 10px;
}

.document-card-box:hover{
    cursor: pointer;
    box-shadow: 0 5px 5px #e3e3e3;
}

.order-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 1px 1px #e3e3e3;
    cursor: pointer;
}

.order-form:hover{
    box-shadow: 0 5px 5px #e3e3e3;
}

@media only screen and (max-width: 600px) {
    .edu-card{
        &.card-type-1{
            .inner{
                display: flex;
                flex-direction: column;
                .thumbnail{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                }
                .content{
                    width: 100%;
                }
            }
        }
        
    }
}